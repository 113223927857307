<template>
  <div>
    <loading v-if="isLoading" />
    <content-not-view v-else-if="!subPermission.index" />
    <b-card v-else>
      <div class="custom-search d-md-flex justify-content-between mb-1 px-1">
        <!-- search input -->
        <b-form-group class="col-md-11 col-lg-auto float-left">
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t("g.searchLabel") }}</label>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('g.searchHere')"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
        <div
          class="col-md-11 col-lg-auto float-right"
          v-if="subPermission.store"
        >
          <b-button :to="{ name: 'AddReportReasons' }" variant="primary" class="w-100">{{
            $t("g.report-reasons/listOfreportReason/button/add_report_reason")
          }}</b-button>
        </div>
      </div>
      <b-row class="mt-n2">
        <vue-good-table
          class="w-100 m-2"
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table condensed"
          :sort-options="{
            enabled: false,
          }"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
        >
          <template slot="table-column" slot-scope="props">
            <!-- Column: Name -->
            <span
              v-if="props.column.label === 'report_reason_name'"
              class="text-nowrap d-flex justify-content-center"
            >
              {{ $t("g.report-reasons/listOfreportReason/report_reason_name") }}
            </span>
            <span
              v-if="
                props.column.field === 'action' &&
                (subPermission.destroy || subPermission.update)
              "
              class="text-nowrap"
            >
              {{ $t("g.report-reasons/listOfreportReason/actions") }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span
              v-if="props.column.label === 'report_reason_name'"
              class="text-nowrap pl-1 d-flex justify-content-center"
            >
              {{ props.row.name }}
            </span>
            <span
              v-if="
                props.column.field === 'action' &&
                (subPermission.destroy || subPermission.update)
              "
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="subPermission.update"
                    :to="{
                      name: 'editReportReasons',
                      params: { id: props.row.id },
                    }"
                  >
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span>{{ $t("g.edit") }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="subPermission.destroy"
                    @click="deleteAlert(props.row.id)"
                  >
                    <feather-icon icon="Trash2Icon" class="mr-50" />
                    <span>{{ $t("g.delete") }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>
          <template slot="emptystate">
          <span class="text-center" style="width: 100%; display: inline-block;">
            {{ $t("g.no_data_for_table") }}
          </span>
        </template>
        </vue-good-table>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BAvatar,
  BRow,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import loading from "@/components/loading/loading.vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import "vue-good-table/dist/vue-good-table.css";
import contentNotView from "@/components/contentNotView/contentNotView.vue";
export default {
  name: "ListOfEducationsLevels",
  components: {
    BAvatar,
    BRow,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    BDropdownItem,
    BDropdown,
    VBTooltip,
    VueGoodTable,
    loading,
    contentNotView,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      columns: [
        {
          label: "report_reason_name",
          field: "name",
        },
        {
          label: "actions",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: null,
    };
  },
  mounted() {
    this.getLevelsList();
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "report-reasons");
      return this.$store.state.userData.sub_Permission;
    },
  },
  methods: {
    getLevelsList() {
      this.$http.get("admin/report-reasons").then((res) => {
        this.rows = res.data.data;
        this.isLoading = false;
      });
    },
    deleteAlert(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteEduLevel(id);
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    deleteEduLevel(id) {
      this.$http
        .delete(`admin/report-reasons/${id}`)
        .then((res) => {
          if (res.status === 200) {
            this.getLevelsList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
