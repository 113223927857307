var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('loading'):(!_vm.subPermission.index)?_c('content-not-view'):_c('b-card',[_c('div',{staticClass:"custom-search d-md-flex justify-content-between mb-1 px-1"},[_c('b-form-group',{staticClass:"col-md-11 col-lg-auto float-left"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t("g.searchLabel")))]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":_vm.$t('g.searchHere'),"type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)]),(_vm.subPermission.store)?_c('div',{staticClass:"col-md-11 col-lg-auto float-right"},[_c('b-button',{staticClass:"w-100",attrs:{"to":{ name: 'AddReportReasons' },"variant":"primary"}},[_vm._v(_vm._s(_vm.$t("g.report-reasons/listOfreportReason/button/add_report_reason")))])],1):_vm._e()],1),_c('b-row',{staticClass:"mt-n2"},[_c('vue-good-table',{staticClass:"w-100 m-2",attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table condensed","sort-options":{
          enabled: false,
        },"search-options":{
          enabled: false,
          externalQuery: _vm.searchTerm,
        }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'report_reason_name')?_c('span',{staticClass:"text-nowrap d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.$t("g.report-reasons/listOfreportReason/report_reason_name"))+" ")]):_vm._e(),(
              props.column.field === 'action' &&
              (_vm.subPermission.destroy || _vm.subPermission.update)
            )?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.report-reasons/listOfreportReason/actions"))+" ")]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.label === 'report_reason_name')?_c('span',{staticClass:"text-nowrap pl-1 d-flex justify-content-center"},[_vm._v(" "+_vm._s(props.row.name)+" ")]):_vm._e(),(
              props.column.field === 'action' &&
              (_vm.subPermission.destroy || _vm.subPermission.update)
            )?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(_vm.subPermission.update)?_c('b-dropdown-item',{attrs:{"to":{
                    name: 'editReportReasons',
                    params: { id: props.row.id },
                  }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.edit")))])],1):_vm._e(),(_vm.subPermission.destroy)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteAlert(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Trash2Icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.delete")))])],1):_vm._e()],1)],1)]):_vm._e()]}}])},[_c('template',{slot:"emptystate"},[_c('span',{staticClass:"text-center",staticStyle:{"width":"100%","display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.$t("g.no_data_for_table"))+" ")])])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }